import { useEffect, useState } from 'react';
import axios from 'axios';

import sgLogo from 'assets/images/sg-logo.png';
import './style.scss';
import Icons from 'common/Icons';
import StatusSection from './StatusSection';
import RepairStatusProgress from './RepairStatusProgress';
import Ring from 'components/Ring';
import { STATUSES } from 'utils/constants';
import { REPAIR_STATUSES_MAPPING } from '../constants';
import {
  findNewest,
  mapResult,
  defineProgress,
  formatDateValue,
  getEstimatedCompletionDate,
  URL,
} from '../utils';

const RepairStatusResult = ({ license = '' }) => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    setLoading(true);
    async function getClaims() {
      await axios
        .get(URL(license))
        .then(({ data }) => {
          const claims = data?.claims;
          if (claims?.length) {
            const openedClaims = claims.filter(
              (c) => c.status === STATUSES.open
            );
            const newestClaim = findNewest(openedClaims);
            setResult(newestClaim);
          } else {
            setError('Sorry, no claims have been found.');
          }
          setLoading(false);
        })
        .catch((e) => {
          e instanceof Error ? console.log(e.message) : console.log(e);
          setLoading(false);
        });
    }
    if (license) {
      getClaims();
    }
  }, [license]);

  useEffect(() => {
    if (error) {
      console.log(error);
    }
  }, [error]);

  const mappedFields = mapResult(result?.details);

  const sectionContent = mappedFields
    ? Object.keys(mappedFields).map((title, index) => (
        <StatusSection
          sectionTitle={title}
          sectionData={mappedFields[title]}
          index={index}
        />
      ))
    : '';

  const repairProgressStatus = mappedFields ? defineProgress(mappedFields) : '';
  const esimatedCompletionDate = formatDateValue(
    getEstimatedCompletionDate(
      REPAIR_STATUSES_MAPPING[repairProgressStatus],
      result?.details
    )
  );

  if (loading) return <Ring />;

  return (
    <div className="repair-status-holder">
      <div className="repair-status">
        <div className="repair-status-brand">
          <img
            className="alignnone  wp-image-9652"
            src={sgLogo}
            // src="https://www.sandgresponse.co.uk/wp-content/uploads/2018/04/SG-logo-horizontal-grey-1-300x130.png"
            alt="S&G Response"
            width="260"
            height="79"
            // width="155"
            // height="67"
          />
        </div>

        <div className="repair-status-statuses">
          <RepairStatusProgress repairProgressStatus={repairProgressStatus} />
        </div>

        <div className="repair-status-date">
          <Icons iconName="calendar" fill="black" width={42} height={42} />
          <div className="repair-status-date-content">
            <div className="repair-status-date-content-title">
              {/* Estimated Completion Date: */}
              {repairProgressStatus}:
            </div>
            <div className="repair-status-date-content-value">
              {esimatedCompletionDate}
            </div>
          </div>
        </div>

        {sectionContent}
      </div>
    </div>
  );
};

export default RepairStatusResult;
