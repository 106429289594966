import { useState, useCallback } from 'react';
import RepairStatusResult from './components/RepairStatusResult';
import RepairStatusLogin from './components/RepairStatusLogin';

const LICENSE_PLATE_REGEX = /^[A-Z]{2}\d{2}[A-Z]{3}$/gm;
const LICENSE_PLATE_LENGTH = 7;
export const currentDate = new Date().toISOString().split('T')[0];
export const MIN_DATE = '1950-01-01';

const validateLicensePlate = (licensePlate) =>
  LICENSE_PLATE_REGEX.test(licensePlate);

const validateDate = (incidentDate) =>
  incidentDate >= MIN_DATE && incidentDate <= currentDate;

const RepairStatusPage = () => {
  const [licensePlateValid, setLicensePlateValid] = useState(false);
  // eslint-disable-next-line
  const [incidentDateValid, setIncidentDateValid] = useState(false);
  const [license, setLicense] = useState('');
  const [date, setDate] = useState('');
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleContinue = useCallback(() => {
    if (!license)
      return setError('Please provide a vehicle registration number');

    if (!licensePlateValid) {
      const isLicensePlateValid = validateLicensePlate(license);

      if (license && !isLicensePlateValid)
        return setError('Please enter a valid vehicle registration number');

      if (isLicensePlateValid && license && !licensePlateValid)
        return setLicensePlateValid(true);
    }

    if (licensePlateValid && license && !date)
      return setError('Please, chose an incident date.');

    const isDateValid = validateDate(date);

    if (!isDateValid)
      return setError(
        `Please provide an incident date between ${MIN_DATE} and ${currentDate}`
      );

    if (date && isDateValid) setIncidentDateValid(true);

    if (!isAuthenticated) {
      setIsAuthenticated(true);
    }
  }, [
    license,
    setLicensePlateValid,
    date,
    setIncidentDateValid,
    licensePlateValid,
    setIsAuthenticated,
    isAuthenticated,
  ]);

  const handleLicense = useCallback(
    (e) => {
      const val = e.target?.value?.toUpperCase();

      if (error) {
        setError(null);
      }

      if (val?.length > LICENSE_PLATE_LENGTH) {
        setLicense(val.slice(0, -1));
      } else {
        setLicense(val);
      }
    },
    [error, setError]
  );

  const handleDate = useCallback(
    (e) => {
      if (error) {
        setError(null);
      }

      const date = e.target.value;
      setDate(date);
    },
    [setDate, error]
  );

  const repairLoginProps = {
    handleDate,
    handleLicense,
    license,
    licensePlateValid,
    handleContinue,
    error,
  };

  // return true ? (
  return isAuthenticated && license ? (
    <RepairStatusResult license={license} date={date} />
  ) : (
    <RepairStatusLogin {...repairLoginProps} />
  );
};

export default RepairStatusPage;
